import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import GenericLayout from '../components/PagesLayout/generic';

const GenericTemplate = (props) => <GenericLayout {...props} />;

GenericTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default GenericTemplate;

export const query = graphql`
         query genericQuery($locale: String!) {
           content: allPrismicGenericPage(filter: { lang: { eq: $locale } }) {
             nodes {
               uid
               data {
                 title {
                   text
                 }
                 hide_image
                 image {
                   localFile {
                     childImageSharp {
                       fluid(toFormat: JPG) {
                         ...GatsbyImageSharpFluid_noBase64
                       }
                     }
                   }
                 }
                 body {
                   html
                 }
                 excerpt {
                   html
                 }
                 sections {
                   __typename
                   section {
                     document {
                       ... on PrismicOurValues {
                         data {
                           title {
                             text
                           }
                           body {
                             text
                           }
                           sections {
                             section_title {
                               html
                             }
                             section_body {
                               text
                             }
                           }
                         }
                       }
                       ... on PrismicTeam {
                         id
                         data {
                           title {
                             text
                           }
                           sections {
                             section {
                               document {
                                 data {
                                   name {
                                     text
                                   }
                                   qualification {
                                     html
                                   }
                                   position {
                                     html
                                   }
                                   body {
                                     html
                                   }
                                   image {
                                     localFile {
                                       childImageSharp {
                                         fluid(toFormat: JPG) {
                                           ...GatsbyImageSharpFluid_noBase64
                                         }
                                       }
                                     }
                                   }
                                 }
                               }
                               uid
                             }
                           }
                         }
                       }
                       ... on PrismicContentBlock {
                         id
                         data {
                           title {
                             text
                           }
                           body {
                             html
                           }
                           image {
                             url
                           }
                           title_link {
                             url
                           }
                           link_to_pdf {
                             url
                           }
                           image_link {
                             url
                           }
                           block_link {
                             url
                           }
                         }
                       }
                     }
                   }
                 }
               }
             }
           }
         }
       `;
